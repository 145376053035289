import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMarketingHubEmailBroadcastsEnabled } from "./useIsMarketingHubEmailBroadcastsEnabled";

export const useIsNonMemberContactsEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isMarketingHubEmailBroadcastsEnabled =
    useIsMarketingHubEmailBroadcastsEnabled();

  return (
    isMarketingHubEmailBroadcastsEnabled &&
    Boolean(currentCommunitySettings?.allow_non_member_contacts)
  );
};
